import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';

export const FollowTopbarWrapper: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <>
      {children}
      <Spacer extend={{ untilL: { display: 'none' } }} />
    </>
  );
};

export const SUBMENU_HEIGHT = 48;
export const SUBMENU_HEIGHT_FROML = 64;

const Spacer: React.FC<React.PropsWithChildren<{ extend?: ExtendCSS }>> = ({
  extend,
}) => {
  return (
    <Block
      extend={[
        {
          height: SUBMENU_HEIGHT,
          fromL: { height: SUBMENU_HEIGHT_FROML },
        },
        extend,
      ]}
    />
  );
};
