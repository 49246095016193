// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-imports */
import React from 'react';
import { ExtendCSS, Flex, Block } from 'vcc-ui';
import Icon from '@vcc-www/legacy-icon';
import { useScrollLock } from '@vcc-www/hooks';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { LinkText } from './LinkText';

type Props = {
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  isOpen?: boolean;
  header: string;
  ariaControls?: string;
};

export const DropDown: React.FC<React.PropsWithChildren<Props>> = ({
  setIsOpen,
  isOpen = false,
  header,
  ariaControls = '',
}) => {
  useScrollLock(isOpen);

  const translate = useSharedComponentsTranslate();
  const closeMenu = translate('LocalSubmenu.accessibilityLabels.closeMenu');
  const openMenu = translate('LocalSubmenu.accessibilityLabels.openMenu');

  return (
    <Block
      as="button"
      data-testid="localSubMenu:dropDown"
      data-autoid="localSubMenu:dropDown"
      extend={submenuContainerCSS}
      aria-label={isOpen ? closeMenu : openMenu}
      aria-expanded={isOpen}
      aria-controls={ariaControls}
      onClick={() => setIsOpen((isOpen) => !isOpen)}
    >
      <Flex extend={mobileWrapperCSS}>
        <Block extend={headerCSS} data-autoid="localSubMenu:activeLink">
          <LinkText active>{header}</LinkText>
        </Block>
        <Block extend={iconCSS}>
          <Icon
            extend={{
              '& path': {
                strokeWidth: 1,
              },
            }}
            type={isOpen ? 'chevron-up-medium' : 'chevron-down-medium'}
          />
        </Block>
      </Flex>
    </Block>
  );
};

const submenuContainerCSS: ExtendCSS = ({
  theme: { color, baselineGrid },
}) => ({
  width: '100%',
  padding: `${baselineGrid + 1}px 0px`,
  position: 'relative',
  margin: 0,
  borderTop: 'none',
  borderRight: 'none',
  borderBottom: `1px solid ${color.ornament.divider}`,
  borderLeft: 'none',
  backgroundColor: color.background.primary,
  zIndex: 'calc(var(--sitenav-z-index-min, 990) - 1)',
  fromL: {
    display: 'none',
  },
});

const headerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'block',
  paddingLeft: 3 * baselineGrid,
});

const iconCSS: ExtendCSS = ({ theme: { color, baselineGrid } }) => ({
  color: color.foreground.primary,
  paddingRight: 3 * baselineGrid,
});

const mobileWrapperCSS: ExtendCSS = {
  flexDirection: 'row',
  justifyContent: 'space-between',
};
