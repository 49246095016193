import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { Click } from '@vcc-www/buttons';
import { LinkWithActive } from './types';
import { LinkText } from './LinkText';

export const LinkWrapper: React.FC<React.PropsWithChildren<LinkWithActive>> = ({
  href,
  text,
  active = false,
  contentPath,
  target,
  type,
  ctaTrackingLabel,
  onClick,
}) => {
  return type !== 'None' && text ? (
    <Block extend={linkWrapperCSS} data-autoid="localSubMenu:links">
      <Click
        href={href}
        data-autoid={`localSubMenu:links:${text
          ?.replace(/\s/g, '')
          .toLowerCase()}`}
        trackEventLabel={
          ctaTrackingLabel
            ? `${contentPath} | ${ctaTrackingLabel}`
            : contentPath
        }
        extend={linkCSS(active)}
        data-active={active}
        target={target}
        onClick={onClick}
      >
        <LinkText active={active}>{text}</LinkText>
      </Click>
    </Block>
  ) : null;
};

const linkWrapperCSS: ExtendCSS = ({ theme: { baselineSubGrid } }) => ({
  paddingTop: 3 * baselineSubGrid - 1,
  paddingBottom: 2 * baselineSubGrid - 1,
  fromL: {
    paddingLeft: 3 * baselineSubGrid,
    paddingRight: 3 * baselineSubGrid,
    display: 'inline-block',
  },
});

const linkCSS =
  (active: boolean): ExtendCSS =>
  ({ theme: { color, baselineSubGrid } }) => ({
    display: 'inline-block',
    paddingBottom: baselineSubGrid,
    borderBottom: `2px solid ${
      active ? color.foreground.action : 'transparent'
    }`,
    ...(!active && {
      ':hover em': {
        color: color.foreground.action,
      },
      ':active em': {
        color: color.foreground.primary,
      },
    }),
  });
