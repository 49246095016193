import React from 'react';
import { ExtendCSS, Text } from 'vcc-ui';

export const LinkText: React.FC<
  React.PropsWithChildren<{ active?: boolean }>
> = ({ active = false, children }) => (
  <Text variant="columbus" subStyle="emphasis" extend={linkTextCSS(active)}>
    {children}
  </Text>
);

const linkTextCSS =
  (active: boolean): ExtendCSS =>
  ({ theme: { color } }) => ({
    color: color.foreground[active ? 'primary' : 'secondary'],
  });
