import React from 'react';
import LocalSubmenu from '@vcc-www/local-submenu';
import LocalSubmenuProps from './LocalSubmenu.props';

const LocalSubmenuEditorialComponent: React.FC<LocalSubmenuProps> = ({
  content,
}) => {
  const items = content?.items.map((item) => item.link);
  return <LocalSubmenu items={items} />;
};
export default LocalSubmenuEditorialComponent;
