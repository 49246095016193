import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';

type Props = {
  show?: boolean;
  onClick?: () => void;
  className?: string;
  extend?: ExtendCSS;
  id?: string;
};

function Backdrop({
  id = '',
  show = false,
  onClick,
  className = '',
  extend,
}: Props) {
  return (
    <Block
      id={id}
      aria-hidden={!show}
      className={className}
      extend={[backdropCSS, extend]}
      onClick={onClick}
    />
  );
}

const backdropCSS: ExtendCSS = ({ theme: { color } }) => ({
  background: color.foreground.primary,
  height: '100%',
  left: 0,
  opacity: 0,
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  transition: 'opacity 300ms ease-in-out',
  width: '100%',
  zIndex: 999,
});

export default Backdrop;
