import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { ExtendCSS, Block, useTheme } from 'vcc-ui';
import { urlFromString, useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { useTopbarPosition } from '@volvo-cars/site-nav-embed/src/useTopbarPosition';
import Backdrop from './Backdrop';
import { LinkField, LinkWithActive } from './types';
import { FollowTopbarWrapper } from './FollowTopbarWrapper';
import { LinkWrapper } from './LinkWrapper';
import { DropDown } from './DropDown';
import { ChildLinkWrapper } from './ChildLinkWrapper';

type Props = {
  items: LinkField[];
  ignoreWillChange?: boolean;
  onDropdownToggle?: (isOpen: boolean) => void;
};

/**
 *  @deprecated
 *  Use local-submenu component from '@volvo-cars/react-local-submenu' instead
 *  https://vcc-ui.vercel.app/docs/packages/react-local-submenu
 **/
const LocalSubmenu: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  ignoreWillChange,
  onDropdownToggle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState('');
  const [augmentedHeaderText, setAugmentedHeaderText] = useState('');
  const [ariaControls, setAriaControls] = useState<undefined | string>(
    undefined,
  );
  const { contentPath: pageContentPath } = useVolvoCarsUrl();
  const { direction } = useTheme();

  useEffect(() => onDropdownToggle?.(isOpen), [isOpen, onDropdownToggle]);
  useTopbarPosition('relative');

  const links: LinkWithActive[] = useMemo(
    () =>
      items?.map((link) => {
        const contentPath = urlFromString(link.href).contentPath;
        const isActive = contentPath === pageContentPath;
        if (
          !augmentedHeaderText &&
          link.type === 'WithChildren' &&
          link?.text
        ) {
          setAugmentedHeaderText(link.text);
        }
        if (augmentedHeaderText && isActive) {
          setHeader(`${link.text} - ${augmentedHeaderText}`);
        } else if (link?.text && isActive) {
          setHeader(link.text);
        }
        return { ...link, active: isActive, contentPath };
      }),
    [augmentedHeaderText, items, pageContentPath],
  );
  useEffect(() => {
    setAriaControls(nanoid());
  }, []);

  return (
    <FollowTopbarWrapper>
      <Block
        data-autoid="localSubMenu:container"
        extend={{
          zIndex: 'calc(var(--sitenav-z-index-min, 990) - 1)',
          position: 'sticky',
          top: 0,
        }}
        dir={direction}
      >
        <Backdrop
          show={isOpen}
          onClick={() => setIsOpen(false)}
          extend={{
            zIndex: 'calc(var(--sitenav-z-index-min, 990) - 2)',
            height: '110vh',
          }}
        />
        <DropDown
          header={header}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          ariaControls={ariaControls}
        />
        <Block
          id={ariaControls}
          extend={linksListWrapperCSS(isOpen, ignoreWillChange)}
        >
          <Block
            extend={linksListCSS}
            data-autoid="localSubMenu:linksContainer"
          >
            {links?.map((linkItem, index) => {
              // Ignore the ExactPathMatch Links
              if (linkItem?.type === 'ExactPathMatchRequired') {
                return null;
              }

              if (linkItem?.childLinks) {
                return RenderWithChildItems(linkItem, index, pageContentPath);
              }

              return <LinkWrapper {...linkItem} key={index} />;
            })}
          </Block>
        </Block>
      </Block>
    </FollowTopbarWrapper>
  );
};

export default LocalSubmenu;

const RenderWithChildItems = (
  linkItem: { active?: boolean; contentPath: string } & LinkField,
  index: number,
  pageContentPath: string,
) => {
  return (
    <React.Fragment key={index}>
      <LinkWrapper {...linkItem} ctaTrackingLabel={linkItem.text} />
      {linkItem?.childLinks?.map((childLink, index) => {
        const contentPath = urlFromString(
          childLink?.link?.href || '',
        ).contentPath;
        const isActive = contentPath === pageContentPath;

        return (
          <ChildLinkWrapper
            key={index}
            contentPath={contentPath}
            active={isActive}
            {...childLink.link}
            ctaTrackingLabel={childLink.link.text}
          />
        );
      })}
    </React.Fragment>
  );
};

const linksListWrapperCSS =
  (isOpen: boolean, ignoreWillChange?: boolean): ExtendCSS =>
  ({ theme: { color, baselineGrid, baselineSubGrid } }) => ({
    position: 'absolute',
    width: '100%',
    zIndex: 'calc(var(--sitenav-z-index-min, 990) - 2)',
    padding: `${2 * baselineSubGrid}px ${3 * baselineGrid}px`,
    paddingRight: `calc(var(--scrollbar-offset,0px) + ${
      3 * baselineSubGrid
    }px)`,
    backgroundColor: color.background.primary,
    ...(!ignoreWillChange && { willChange: 'transform' }),
    untilL: {
      opacity: isOpen ? 1 : 0,
      transform: `translateY(${isOpen ? '0px' : '-100%'})`,
      transition: `transform 300ms ease-in-out, opacity 0ms linear ${
        isOpen ? '0ms' : '300ms'
      }`,
      borderBottom: isOpen ? `1px solid ${color.ornament.divider}` : 'none',
    },
  });

const linksListCSS: ExtendCSS = ({
  theme: {
    color: { background, ornament },
  },
}) => ({
  position: 'relative',
  fromL: {
    textAlign: 'center',
    display: 'block',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '::-webkit-scrollbar': {
      backgroundColor: background.primary,
      width: 12,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: background.primary,
    },
    '::-webkit-scrollbar-track:hover': {
      backgroundColor: background.primary,
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: 12,
      border: `5px solid ${background.primary}`,
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#7f7d7f',
      border: `4px solid ${background.primary}`,
    },

    '::-webkit-scrollbar-button': { display: 'none' },
    ':hover::-webkit-scrollbar-thumb': {
      backgroundColor: ornament.divider,
    },
  },
});
